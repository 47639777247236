import styles from "./downloads.module.css";
import {DownloadTableExcel} from 'react-export-table-to-excel';
import {useState} from "react";
import {CSVLink} from "react-csv";
import dayjs from "dayjs";

export default function Downloads({tableId, tableRef, data, name}) {
    const [copyText, setCopyText] = useState("Copy Data")
    const copyTable = () => {
        const elTable = document.querySelector(`#${tableId}`);

        let range, sel;

        // Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {

            range = document.createRange();
            sel = window.getSelection();
            // unselect any element in the page
            sel.removeAllRanges();

            try {
                range.selectNodeContents(elTable);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(elTable);
                sel.addRange(range);
            }

            document.execCommand('copy');
            setCopyText("Copied!")
            let intv = setInterval(() => {
                setCopyText("Copy Data");
                clearInterval(intv);
            }, 2000);
        }

        sel.removeAllRanges();
    }

    return (
        <div className={["container-fluid p-3", styles.container].join(' ')}>
            <div className="row">
                {/* <div className="col-sm-6 col-md-2 col-6 mb-2">
                    <button className="btn bgDanger text-white">Export PDF</button>
                </div> */}
                <div className=" col-sm-3 col-5 mb-2">
                    <DownloadTableExcel
                        filename={`${name} ${new Date()}`}
                        sheet={name}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn bgWarning text-white">Export Excel</button>
                    </DownloadTableExcel>
                </div>
                <div className=" col-sm-3 col-5 mb-2">

                    <CSVLink data={data} filename={`${name} ${dayjs(Date.now()).format("MMM DD, YYYY")} `} >
                        <button className="btn bgDanger text-white">Export CSV </button>
                    </CSVLink>

                </div>
                <div className=" col-sm-3 col-5 mb-2">
                    <button className="btn bg-light" onClick={copyTable}>{copyText}</button>
                </div>
            </div>
        </div>
    )
}