import {useState} from "react";
import {Link} from "react-router-dom";
import {navbarItems} from "../../utils/constants";
import Logo from "../logo/Logo";
import ProfileDropdown from "./profile-dropdown/ProfileDropdown";
import Sidebar from "../sidebar/Sidebar";
import {useSelector} from "react-redux";
import {loginSelector} from "../../store/slices/auth/loginSlice";

export default function Navbar() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const {userData: {role}} = useSelector(loginSelector).login;

  function handleOpenSidenav() {
    setOpenSidebar(true);
  }

  function handleCloseSidenav() {
    setOpenSidebar(false);
  }
  const teamRoute = {name: "Teams", path: "/teams"};

  return (
    <>
      <nav className="navbar navbar-expand-md align-items-center navbar-dark sticky-top bgPrimary">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <button
              className="navbar-toggler border-0"
              type="button"
              aria-label="Toggle side navigation"
              onClick={handleOpenSidenav}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link to="/" className="navbar-brand">
              <Logo color="white" />
            </Link>
          </div>

          <div className="d-block d-md-none">
            <ProfileDropdown />
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {
                role === "super-admin" ?
                  [...navbarItems, teamRoute].map((item, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        to={item.path}
                        className={`nav-link ${index === 0 && "active"}`}
                        aria-current="page"
                      >
                        {item.name}
                      </Link>
                    </li>
                  )) : navbarItems.map((item, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        to={item.path}
                        className={`nav-link ${index === 0 && "active"}`}
                        aria-current="page"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))

              }
            </ul>
            <div className="d-none d-md-block">
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </nav>
      <Sidebar show={openSidebar} onClose={handleCloseSidenav} />
    </>
  );
}
