import styles from "./custom-input.module.css";

export default function CustomInput(props) {
    const {withPrepend, withAppend, prependValue, ...rest} = props;

    if(withPrepend) return (
        <div className="input-group">
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
        </div>
    )
    if(withAppend) return (
        <div className="input-group">
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
        </div>
    )
    if(!withPrepend && !withAppend) return <input className={[ "form-control" , styles.customInput].join(' ')} {...rest} />
}