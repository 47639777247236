import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import { Link } from "react-router-dom";
import Downloads from "../../widgets/downloads/Downloads";
import TeamsTable from "../../components/teams/TeamsTable";
// import {teams} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  dashboardSelector,
  getTeams,
} from "../../store/slices/dashboard/dashboardSlice";
import { loginSelector } from "../../store/slices/auth/loginSlice";
import FetchError from "../../widgets/others/FetchError";
import Loader from "../../widgets/others/Loader";

export default function Teams() {
  const {
    userData: { id },
  } = useSelector(loginSelector).login;
  const { teams, teamsIsLoading, teamError } =
    useSelector(dashboardSelector).dashboard;
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState("");
  const [filteredTeams, setFilteredTeams] = useState(teams);
  const [searchedTeams, setSearchedTeams] = useState(filteredTeams);
  const [searchValue, setsearchValue] = useState("");
  const [filterValues, setFilterValues] = useState({ entries: "" });

  const refetchTeams = useCallback(
    (status) => {
      dispatch(
        getTeams(status, {
          adminId: id,
          super_adminId: id,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  useEffect(() => {
    refetchTeams(true);
  }, [refetchTeams]);

  useEffect(() => {
    setFilteredTeams(teams);
    setFilter(filterValues);
    //eslint-disable-next-line
  }, [teams]);

  const setFilter = (values) => {
    setFilterValues(values);
    // setFilteredTeams(
    //   teams.filter((each, i) => (
    //     (Number(values.entries) === 0 || (i + 1 <= Number(values.entries)))
    //     // (!values.date || Date(each.createdAt).getMilliseconds() === Date(values.date).getMilliseconds())
    //   ))
    // );
  };

  useEffect(() => {
    setSearchedTeams(filteredTeams);
    searchTeams(searchValue);
    //eslint-disable-next-line
  }, [filteredTeams]);

  const searchTeams = (searchText) => {
    setsearchValue(searchText);
    setSearchedTeams(
      filteredTeams.filter((each, i) =>
        // each.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
        // each.last_name.toLowerCase().includes(searchText.toLowerCase())
        each.first_name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setCsvData(
      searchedTeams.map(
        ({ first_name, last_name, phone, email, createdAt }) => ({
          "First Name": first_name,
          "Last Name": last_name,
          "Phone Number": `PH:${phone}`,
          Email: email,
          "Date Added": dayjs(createdAt).format("MMM DD, YYYY"),
        })
      )
    );
  }, [searchedTeams]);

  const tableRef = useRef(null);

  return (
    <>
      <PageHeader
        title="Teams"
        titlePosition="secondary"
        withSearch={true}
        withCenterButton={true}
        CenterButton={<AddNewMemberButton />}
        search={searchTeams}
      />

      {teamsIsLoading && <Loader height={"40vh"} />}

      {teamError && !teamsIsLoading && (
        <FetchError
          message={"An error occured, please check your connection"}
          minHeight={"40vh"}
          btnText={"Try Again"}
          onClick={() => refetchTeams(false)}
        />
      )}

      {!teams[0] && !teamsIsLoading && !teamError && (
        <FetchError
          message={"No admin found"}
          minHeight={"40vh"}
          btnText={"Refresh"}
          onClick={() => refetchTeams(false)}
        />
      )}

      {teams[0] && !teamsIsLoading && !teamError && (
        <div className="container-fluid pt-4">
          <section className="row">
            <div className="col-sm-12">
              <Downloads
                name="Teams"
                tableId="teamsTable"
                tableRef={tableRef}
                data={csvData}
              />
              <TeamsTable tableRef={tableRef} teams={searchedTeams} />
            </div>
          </section>
        </div>
      )}
    </>
  );
}

function AddNewMemberButton() {
  return (
    <Link to="/teams/add" className="btn bgPrimary text-white">
      New Member
    </Link>
  );
}
