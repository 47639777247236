import styles from "./contact-group.module.css";

export default function ContactGroup({data}) {
    // const [contactList, setContactList] = useState(data);

    // function handleAddNewContact() {
    //     let contact = {firstName: "New", lastName: "Name"};
    //     setContactList([...contactList, contact]);
    // }

    return (
        <div className="d-flex gap-4 flex-nowrap overflow-auto removeScrollbar position-relative">
            {/* <AddContactCard handleAddNewContact={handleAddNewContact} /> */}
            {data.length && [...data].reverse().filter((each, i) => i < 10).map((each, index) => <ContactCard key={index} data={each} />)}
        </div>
    )
}

function ContactCard({data: {first_name, last_name}}) {
    return (
        <div className={styles.cardContainer}>
            <div className={styles.avatar}>{`${first_name.charAt(0)} ${last_name.charAt(0)}`}</div>
            <div className={styles.avatarText}>{`${first_name} ${last_name}`}</div>
        </div>
    )
}

// function AddContactCard({handleAddNewContact}) {
//     return (
//         <div className="text-center" onClick={handleAddNewContact}>
//             <div className={styles.avatarAdd}>+</div>
//             <div className={styles.avatarText}>Select Saver</div>
//         </div>
//     )
// }