import dayjs from "dayjs";
import styles from "./data-card.module.css";
// import {formatNumberInThousand} from "../../../utils/functions";
import CustomIcon from "../../../widgets/icon/CustomIcon";

export default function DataCard({ data, children }) {
  return (
    <div className="col-sm-10 col-md-6 col-lg-3 col-10 p-2 d-flex justify-content-stretch">
      <div
        className={["card border-0 p-3 w-100", styles.dataCard].join(" ")}
        style={{ backgroundColor: data?.backgroundColor, color: data?.color }}
      >
        <div>
          <div className="d-flex justify-content-between small">
            <div>
              {data.icon && <CustomIcon name={data.icon} size={30} />}
              <div className="balance-text mt-2">{data?.title}</div>
            </div>
            {data?.date && (
              <div className="balance-text extraSmallText">
                {dayjs(data?.date).format("MMM DD, YYYY")}
              </div>
            )}
          </div>
        </div>
        <h2 className="fw-bold">
          {/* {formatNumberInThousand(data?.value.toFixed(2))} */}
          {data?.value}
        </h2>
        <div className={styles.subtitle}>{data?.subtitle}</div>
        <div className="d-flex">{children}</div>
      </div>
    </div>
  );
}
