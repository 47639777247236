import styles from "./custom-auth-input.module.css";

export default function CustomAuthInput(props) {
    const {withPrepend, withAppend, prependValue, textColor, ...rest} = props;

    if(withPrepend) return (
        <div className="input-group">
            <span className={[`input-group-text ${textColor === 'white' ? 'text-white' : 'text-muted'}`, styles.customAuthInput].join(' ')}>{prependValue}</span>
            <input {...rest} className={["form-control", styles.customAuthInput].join(' ')} />
        </div>
    )
    if(withAppend) return (
        <div className="input-group">
            <input {...rest} className={["form-control", styles.customAuthInput].join(' ')} />
            <span className={[`input-group-text ${textColor === 'white' ? 'text-white' : 'text-muted'}`, styles.customAuthInput].join(' ')}>{prependValue}</span>
        </div>
    )
    if(!withPrepend && !withAppend) return <input className={[`form-control ${textColor === 'white' ? 'text-white' : 'text-muted'}` , styles.customAuthInput].join(' ')} {...rest} />
}