import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import CustomAuthInput from "../../../widgets/form/custom-auth-input/CustomAuthInput";
import {useDispatch} from "react-redux";
import CustomButton from "../../../widgets/form/custom-button/CustomButton";
import {login} from "../../../store/slices/auth/loginSlice";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Alert} from "react-bootstrap";

const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Not a valid email ")
    .required("Your email is required"),
  password: Yup.string("Enter Password")
    .min(8, "Too short, must be at least 8 in length!")
    .required("Password is required"),
});

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    setError(null);
    await dispatch(login(e, {
      navigate, setError
    }));
    setLoading(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {error &&
        <Alert variant="danger">
          <span className="text-danger">{error.text}</span>
        </Alert>
      }
      <div className="mb-4">
        <CustomLabel htmlFor="email" content="Email" />
        <CustomAuthInput
          type="email"
          aria-label="email"
          aria-describedby="Email"
          name="email"
          placeholder="Email"
          textColor="white"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && (
          <div className="form-text text-danger">{formik.errors.email}</div>
        )}
      </div>
      <div className="mb-4">
        <CustomLabel htmlFor="password" content="Password" />
        <CustomAuthInput
          type="password"
          placeholder="Password"
          aria-label="Password"
          aria-describedby="password"
          name="password"
          textColor="white"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        {formik.touched.password && (
          <div className="form-text text-danger">{formik.errors.password}</div>
        )}
      </div>
      <div className="d-grid">
        <CustomButton
          type="submit"
          disabled={loading || formik.values.password.length < 8}
          loading={loading}
          color="primary"
          text="Log In"
        />
      </div>
    </form>
  );
}
