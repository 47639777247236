import dayjs from "dayjs";
import PropTypes from "prop-types";
import {nairaSign} from "../../../utils/constants";
import {formatNumberInThousand} from "../../../utils/functions";
// import CustomButtonWithIcon from "../../../widgets/form/custom-button/CustomButtonWithIcon";
import CustomIcon from "../../../widgets/icon/CustomIcon";

export default function TransactionDetails({data}) {

  return (
    <>
      <div className="fw-light mt-4 mb-3 small">{dayjs(data?.createdAt).format("MMM DD, YYYY HH:ss")}</div>
      <ReceiptLine
        verticalReverse={true}
        leadTitle={true}
        title={``}
        value={nairaSign + formatNumberInThousand(data?.amount)}
      />
      <ReceiptLine title="From" value={`${data?.sender?.first_name} ${data?.sender?.last_name}`} />
      <ReceiptLine title="Description" value={data?.description} />
      {/* <ReceiptLine
        title="Payment Method"
        value="Bank Transfer"
        appendTitle="Fees"
        appendValue={`${nairaSign}0.00`}
      /> */}
      <ReceiptLine
        title="Transaction reference"
        value={data?.purchase_reference}
      />
    </>
  );
}

// function Actions() {
//   return (
//     <div className="d-flex justify-content-between align-items-center">
//       <CustomButtonWithIcon
//         text="Deposit"
//         icon="download"
//         iconColor="green"
//         withShadow
//       />
//       <CustomButtonWithIcon
//         text="Download Receipt"
//         icon="download"
//         iconColor="#355BCF"
//         withShadow
//       />
//       {/* <CustomButtonWithIcon
//         text="Share Details"
//         icon="foreign"
//         iconColor="#14A3B8"
//         withShadow
//       /> */}
//     </div>
//   );
// }

function ReceiptLine({
  title,
  verticalReverse,
  leadTitle,
  value,
  appendTitle,
  appendValue,
  appendIcon,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center border-bottom py-2 mb-3">
      <div className="w-100">
        <ReceiptInfo
          verticalReverse={verticalReverse}
          leadTitle={leadTitle}
          title={title}
          value={value}
        />
      </div>
      {appendTitle ? (
        <ReceiptInfo title={appendTitle} value={appendValue} />
      ) : (
        <CustomIcon name={appendIcon} size={40} />
      )}
    </div>
  );
}

function ReceiptInfo({title, value, leadTitle, verticalReverse}) {
  return (
    <div
      className={`d-flex ${verticalReverse ? "flex-column-reverse" : "flex-column"
        }`}
    >
      {title && <div className="fw-light small mb-2">{title}</div>}
      {value && (
        <div className={`fw-bold small ${leadTitle ? "lead" : ""}`}>
          {value}
        </div>
      )}
    </div>
  );
}

TransactionDetails.propTypes = {
  data: PropTypes.object,
};

ReceiptLine.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  verticalReverse: PropTypes.bool,
  leadTitle: PropTypes.bool,
  appendTitle: PropTypes.string,
  appendValue: PropTypes.string,
  appendIcon: PropTypes.string,
};

ReceiptInfo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  leadTitle: PropTypes.bool,
  verticalReverse: PropTypes.bool,
};
