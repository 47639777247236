// import styles from "./total-transaction.module.css";
import TransactionDataSummary from "../../../widgets/dashboard/transaction-data-summary/TransactionDataSummary";

const totalDataSummary = {
    title: "Total Transaction",
    amount: {
        value: 9000000,
        isMoney: true
    },
    subText2: {
        text: "Total Transaction Volume",
        value: 300,
        isMoney: false
    },
    greenData: {
        text: "Deposit",
        value: 5000000,
        isMoney: true
    },
    redData: {
        text: "Withdrawals",
        value: 4000000,
        isMoney: true
    },
    chartData: {
        daily: null,
        weekly: null,
        monthly: null
    }
}

export default function TotalTransaction({transactions}) {

    return (
        <TransactionDataSummary details={transactions} isTransaction data={totalDataSummary} />
    )
}