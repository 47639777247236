import Offcanvas from "react-bootstrap/Offcanvas";
import CustomCloseButtonIcon from "../form/custom-button/CustomCloseButtonIcon";
import NavSidebarContent from "../navbar/nav-sidebar-content/NavSidebarContent";

export default function Sidebar({ show, onClose, title, bgColor }) {
  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      className={bgColor || "bgPrimary"}
      style={{ width: "80%" }}
    >
      <div className="d-flex justify-content-end mt-3">
        <CustomCloseButtonIcon onClose={onClose} color="white" />
      </div>
      {title && (
        <Offcanvas.Header>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
      )}
      <Offcanvas.Body>
        <NavSidebarContent />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
