import {Spinner} from "react-bootstrap";

export default function CustomButton({type, color, text, disabled, onClick, loading}) {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={`btn customButton ${color === "primary"
        ? "customPrimaryButton text-white"
        : color === "secondary"
          ? "customSecondaryButton"
          : ""
        }`}
    >
      {text}
      {loading && <span>&nbsp;<Spinner animation="border" size="sm" /></span>}
    </button>
  );
}
