// Your variables goes here

import dayjs from "dayjs";

export const date = new Date();
export const dateFormatted = dayjs(date).format("MMM DD, YYYY");
export const nairaSign = "₦";
export const contributionTypes = ["Daily", "Weekly", "Monthly"];

export const navbarItems = [
  {name: "Dashboard", path: "/"},
  {name: "Merchants", path: "/merchants"},
  // { name: "Loans", path: "/loans" },
  {name: "Transactions", path: "/transactions"},
];

export const dashboardDataCards = [
  {
    title: "Total daily Volume",
    icon: "upDownArrow",
    value: 30,
    backgroundColor: "#061B5A",
    color: "#ffffff",
  },
  // {
  //   title: "60 days late repayment",
  //   icon: "upDownArrow",
  //   value: 740034,
  //   backgroundColor: "#15A4B9",
  //   color: "#ffffff",
  // },
  // {
  //   title: "30 days late repayment",
  //   icon: "walletWhiteBg",
  //   value: 340034,
  //   date: new Date().getFullYear(),
  //   subtitle: "withdraw",
  //   backgroundColor: "#FFE7A5",
  // },
  // {
  //   title: "Total loans granted",
  //   icon: "graphPopout",
  //   value: 400034,
  //   backgroundColor: "#FD8D94",
  //   color: "#ffffff",
  // },
];

export const walletDataCards = [
  {
    title: "Wallet Balance",
    icon: "",
    value: 9000000,
    backgroundColor: "#061B5A",
    color: "#ffffff",
  },
  {
    title: "Available Loan Balance",
    icon: "",
    value: 100000,
    backgroundColor: "#14A3B8",
    subtitle: "Borrow",
    color: "#ffffff",
  },
  {
    title: "Amount Owed",
    icon: "",
    value: 0,
    subtitle: "Repayment Payment due on --/--/--",
    backgroundColor: "#FFE8E9",
  },
];

export const assignNewLoanOverdraft = [
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    creditScore: 89,
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 20000,
    creditScore: 79,
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    creditScore: 59,
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    creditScore: 99,
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    creditScore: 99,
  },
];

export const walletTransactions = [
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 20000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "FirstBank",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
];

export const teams = [
  {
    id: "AJB-354678920",
    name: "Owoeye  Gbenga",
    email: "o.gbenga@ajobank.com",
    isActive: true,
    role: "Admin",
  },
  {
    id: "AJB-354678920",
    name: "Felix Adegboyega",
    email: "a.felix@ajobank.com",
    isActive: true,
    role: "Admin",
  },
  {
    id: "AJB-354678920",
    name: "Olawale Ola",
    email: "o.olawale@ajobank.com",
    isActive: true,
    role: "Publisher",
  },
  {
    id: "AJB-354678920",
    name: "Chisom Umeoke",
    email: "u.chisom@ajobank.com",
    isActive: true,
    role: "Publisher",
  },
  {
    id: "AJB-354678920",
    name: "Alabi Olanike",
    email: "a.olanike@ajobank.com",
    isActive: true,
    role: "Publisher",
  },
  {
    id: "AJB-354678920",
    name: "Praise",
    email: "praise@ajobank.com",
    isActive: true,
    role: "Publisher",
  },
];

export const contributionTransactions = [
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 20000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 8130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
];

export const allLoans = [
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    loanAmount: 200000,
    amountOwed: 100000,
    repaymentData: date,
    status: "active",
  },
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    loanAmount: 200000,
    amountOwed: 100000,
    repaymentData: date,
    status: "active",
  },
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    loanAmount: 200000,
    amountOwed: 100000,
    repaymentData: date,
    status: "active",
  },
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    loanAmount: 200000,
    amountOwed: 100000,
    repaymentData: date,
    status: "active",
  },
];

export const allMerchants = [
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    accountNumber: "0130023455",
    status: "active",
    joined: date,
  },
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    accountNumber: "0130023455",
    status: "active",
    joined: date,
  },
  {
    merchantID: "AJ/02/001",
    name: "Temilade Akinlade Taiwo",
    info: "08194567893",
    accountNumber: "0130023455",
    status: "active",
    joined: date,
  },
];

export const saversTransactions = [
  {
    name: "Temilade Akinlade Taiwo",
    startDate: date,
    amount: 70000,
    type: "Daily",
    bank: "GTB",
    accountNumber: 9130023455,
    duration: "3 months",
    endDate: date,
    phoneNumber: 8123675489,
    action: "View",
  },
  {
    name: "Temilade Akinlade Taiwo",
    startDate: date,
    amount: 70000,
    type: "Weekly",
    bank: "GTB",
    accountNumber: 9130023455,
    duration: "6 months",
    endDate: date,
    phoneNumber: 8123675489,
  },
  {
    name: "Temilade Akinlade Taiwo",
    startDate: date,
    amount: 70000,
    type: "Monthly",
    bank: "Zenith",
    accountNumber: 1433023455,
    duration: "12 months",
    endDate: date,
    phoneNumber: 8123675489,
  },
];

export const dashboardQuickLinks = [
  {
    icon: "add.svg",
    name: "Add New",
    path: "/savers/add",
    isLink: true,
  },
  {
    icon: "download.svg",
    name: "Fund Wallet",
    path: "/",
    isLink: false,
    func: "fund-wallet",
  },
  {
    icon: "arrow-up.svg",
    name: "Pay Out",
    path: "/payout-savings",
    isLink: true,
  },
  {
    icon: "arrow-down.svg",
    name: "Deposit",
    path: "/deposit-savings",
    isLink: true,
  },
  {
    icon: "arrow-up-down.svg",
    name: "Loan",
    path: "/loans",
    isLink: true,
  },
];

export const nigerianBanksList = [
  {id: "1", name: "Access Bank", code: "044"},
  {id: "2", name: "Citibank", code: "023"},
  {id: "3", name: "Diamond Bank", code: "063"},
  {id: "4", name: "Dynamic Standard Bank", code: ""},
  {id: "5", name: "Ecobank Nigeria", code: "050"},
  {id: "6", name: "Fidelity Bank Nigeria", code: "070"},
  {id: "7", name: "First Bank of Nigeria", code: "011"},
  {id: "8", name: "First City Monument Bank", code: "214"},
  {id: "9", name: "Guaranty Trust Bank", code: "058"},
  {id: "10", name: "Heritage Bank Plc", code: "030"},
  {id: "11", name: "Jaiz Bank", code: "301"},
  {id: "12", name: "Keystone Bank Limited", code: "082"},
  {id: "13", name: "Providus Bank Plc", code: "101"},
  {id: "14", name: "Polaris Bank", code: "076"},
  {id: "15", name: "Stanbic IBTC Bank Nigeria Limited", code: "221"},
  {id: "16", name: "Standard Chartered Bank", code: "068"},
  {id: "17", name: "Sterling Bank", code: "232"},
  {id: "18", name: "Suntrust Bank Nigeria Limited", code: "100"},
  {id: "19", name: "Union Bank of Nigeria", code: "032"},
  {id: "20", name: "United Bank for Africa", code: "033"},
  {id: "21", name: "Unity Bank Plc", code: "215"},
  {id: "22", name: "Wema Bank", code: "035"},
  {id: "23", name: "Zenith Bank", code: "057"},
];
