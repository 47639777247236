import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
  userData: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setData: (state, {payload}) => {
      state.userData = payload;
    },
  },
});

//actions
export const {
  setData,
  setToken
} = loginSlice.actions;

//selectors
export const loginSelector = (state) => state;

//reducer
export default loginSlice.reducer;

//Asynchronous thunk action
export const login = (details, actions) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(`${baseUrl}admin/admin_login`, details);
      const data = result.data;
      if (!data.error || data.status) {
        dispatch(setData(data.data));
        actions.navigate("/");
      } else {
        actions.setError({error: true, text: data.message});
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        actions.setError({error: true, text: err.data?.message});
      } else {
        actions.setError({error: true, text: "An error occured, please check your internet connection"});
      }
    }
  };
};


export const logout = (actions) => {
  return async (dispatch) => {
    actions.navigate('/auth/login');
    dispatch(setData(null));
  };
};


