import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import avatar from "../../../assets/icons/navbar/avatar.png";
import useLogout from "../../../hooks/useLogout";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
// import {copyTextToClipboard} from "../../../utils/functions";
// import styles from "./profile-dropdown.module.css";
import Avatar from "../../avatar/Avatar";
import CustomIcon from "../../icon/CustomIcon";
import CustomToast from "../../toast/CustomToast";

// const accountNumber = "0230213457";

const dropdownOptions = [
  // { icon: "settings", name: "Customer Help", path: "/profile" },
];

export default function ProfileDropdown() {
  const {userData} = useSelector(loginSelector).login
  const handleLogOut = useLogout();
  const [showToast, setShowToast] = useState(false);

  // function handleCopy() {
  //   copyTextToClipboard(accountNumber);
  //   setShowToast(true);
  // }

  return (
    <>
      <div className="dropdown">
        <button
          className="btn text-white"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {/* <Avatar src={avatar} alt="user-avatar" width={30} height={30} /> */}
          <span className="d-flex">
            <span className="mx-2 text-capitalize">{userData?.first_name} {userData?.last_name}</span>
            <CustomIcon name="dropdown" size={15} />
          </span>
        </button >
        <ul className="dropdown-menu px-3" aria-labelledby="profile-dropdown">
          <div className="text-center pt-2">
            <div className="mb-2">
              <Avatar src={avatar} alt="user-avatar" />
            </div>
            <div className="fw-bold text-capitalize">{userData?.first_name} {userData?.last_name}</div>
            {/* <div className="textPrimary fwNormal my-2">{userData}</div> */}
            {/* <button
              type="button"
              className="btn btn-sm"
              data-bs-container="body"
              data-bs-toggle="popover"
              data-bs-placement="bottom"
              data-bs-content="Bottom popover"
              onClick={handleCopy}
            >
              <span className="d-flex">
                Copy <CustomIcon name="copy" size={15} />
              </span>
            </button> */}
          </div>
          <hr className="dropdown-divider" />
          {dropdownOptions.map((item, index) => (
            <Link to={item.path} key={index} className="dropdown-item px-1">
              <li className="d-flex align-items-center">
                <span>
                  <CustomIcon name={item.icon} size={15} />
                </span>
                <span className="ps-2">{item.name}</span>
              </li>
            </Link>
          ))}
          <li className="d-flex align-items-center dropdown-item px-1">
            <span className="me-1">
              <CustomIcon name="exit" size={15} />
            </span>
            <span
              className="ps-2 cursorPointer text-danger"
              onClick={handleLogOut}
            >
              Logout
            </span>
          </li>
        </ul>
      </div>
      <CustomToast
        show={Boolean(showToast)}
        title={"Copied to clipboard!"}
        onClose={() => setShowToast("")}
      />
    </>
  );
}
