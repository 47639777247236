import styles from "./custom-select.module.css";

export default function CustomSelect(props) {
    const {withPrepend, withAppend, prependValue, options, ...rest} = props;

    if (withPrepend) return (
        <div className="input-group">
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
        </div>
    )
    if (withAppend) return (
        <div className="input-group">
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
        </div>
    )
    if (!withPrepend && !withAppend) return (
        <select {...rest} class="form-select" aria-label="Default select example">
            {options}
        </select>
    )
}