import React from 'react'
import styles from './styles.module.css'
import logo from "../../assets/images/logo.svg"
import { Spinner } from 'react-bootstrap'

export default function Loader({ height, withLogo }) {
  return (
    <div className="d-flex" style={{ height }}>
      <div className="m-auto d-flex flex-column">
        <Spinner animation="border" className={styles.spinner} size="sm" />
        {withLogo && <img src={logo} alt="logo" className="mt-1" />}
      </div>
    </div>
  )
}
