import axios from "axios";
import {useDispatch} from "react-redux";
import {setData} from "../store/slices/auth/loginSlice";
import baseUrl from "../utils/baseUrl";

export default function useVerify() {
	const dispatch = useDispatch();
	return () => {
		axios.post(`${baseUrl}auth/verify_token`, {token: localStorage.userToken}).then(e => {
			let result = e.data;
			if (result.data) {
				dispatch(setData(result.data))
			}
		})
	}
}