import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import Downloads from "../../widgets/downloads/Downloads";
import MerchantsTable from "../../components/merchants/MerchantsTable";
import ContactGroup from "../../widgets/dashboard/contact-group/ContactGroup";
import { useSelector } from "react-redux";
import {
  dashboardSelector,
  getMerchants,
} from "../../store/slices/dashboard/dashboardSlice";
import Loader from "../../widgets/others/Loader";
import FetchError from "../../widgets/others/FetchError";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

export default function Merchants() {
  const { merchants, merchantIsLoading, merchantError } =
    useSelector(dashboardSelector).dashboard;
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState("");
  const [filteredMerchants, setFilteredMerchants] = useState(merchants);
  const [searchedMerchants, setSearchedMerchants] = useState(filteredMerchants);
  const [searchValue, setsearchValue] = useState("");
  const [filterValues, setFilterValues] = useState({ entries: "" });

  useEffect(() => {
    dispatch(getMerchants(true));
  }, [dispatch]);

  useEffect(() => {
    setFilteredMerchants(merchants);
    setFilter(filterValues);
    //eslint-disable-next-line
  }, [merchants]);

  const setFilter = (values) => {
    setFilterValues(values);
    setFilteredMerchants(
      merchants.filter(
        (each, i) =>
          Number(values.entries) === 0 || i + 1 <= Number(values.entries)
        // (!values.date || Date(each.createdAt).getMilliseconds() === Date(values.date).getMilliseconds())
      )
    );
  };

  useEffect(() => {
    setSearchedMerchants(filteredMerchants);
    searchMerchants(searchValue);
    //eslint-disable-next-line
  }, [filteredMerchants]);

  const searchMerchants = (searchText) => {
    setsearchValue(searchText);
    setSearchedMerchants(
      filteredMerchants.filter(
        (each, i) =>
          each.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
          each.last_name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setCsvData(
      searchedMerchants.map(
        ({
          first_name,
          last_name,
          phone,
          account_number,
          email,
          createdAt,
        }) => ({
          "First Name": first_name,
          "Last Name": last_name,
          "Phone Number": `PH:${phone}`,
          "Account Number": account_number,
          Email: email,
          "Date Joined": dayjs(createdAt).format("MMM DD, YYYY"),
        })
      )
    );
  }, [searchedMerchants]);

  const tableRef = useRef(null);

  return (
    <>
      <PageHeader
        title="Merchants"
        withSearch={true}
        withFilter={true}
        setFilter={setFilter}
        search={searchMerchants}
      />
      {merchantIsLoading && <Loader height={"40vh"} />}

      {merchantError && !merchantIsLoading && (
        <FetchError
          message={"An error occured, please check your connection"}
          minHeight={"40vh"}
          btnText={"Try Again"}
          onClick={() => dispatch(getMerchants())}
        />
      )}

      {!merchants[0] && !merchantIsLoading && !merchantError && (
        <FetchError
          message={"No merchant found"}
          minHeight={"40vh"}
          btnText={"Refresh"}
          onClick={() => dispatch(getMerchants())}
        />
      )}

      {merchants[0] && !merchantIsLoading && !merchantError && (
        <div className="container-fluid px-4 pt-4">
          <section className="row mb-3">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="bgWhite p-3 cardShadow mb-3">
                <div className="text-secondary mb-2 fw-bold">
                  Newly Joined Merchants
                </div>
                <ContactGroup data={merchants} />
              </div>
              <div className="cardShadow">
                <Downloads
                  name="Merchants"
                  tableId={"merchantsTable"}
                  tableRef={tableRef}
                  data={csvData}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="bgWhite p-3">
                <div>
                  <div className="text-secondary fw-bold mb-2">Merchants Count</div>
                  <div className="h2 fw-bold textPrimary">
                    {" "}
                    {searchedMerchants.length}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="row flex-nowrap flex-md-wrap overflow-auto removeScrollbar position-relative">
          { walletDataCards.map((item, index) => <DataCard data={item} key={index} />)}
        </section> */}
          <section className="row">
            <div className="col-sm-12 p-2">
              <MerchantsTable
                tableRef={tableRef}
                merchants={searchedMerchants}
              />
            </div>
          </section>
        </div>
      )}
    </>
  );
}
