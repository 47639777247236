import Card from "../../assets/icons/card.svg";
import BankTransfer from "../../assets/icons/bank-transfer.svg";
import UpDownArrow from "../../assets/icons/up-down-arrow.svg";
import WalletWhiteBg from "../../assets/icons/wallet-white-bg.svg";
import GraphPopout from "../../assets/icons/graph-popout.svg";
import Search from "../../assets/icons/search.svg";
import UserMark from "../../assets/icons/user-mark.svg";
import Settings from "../../assets/icons/settings.svg";
import Exit from "../../assets/icons/exit.svg";
import Copy from "../../assets/icons/copy.svg";
import Dropdown from "../../assets/icons/dropdown.svg";
import ArrowLeftWithBg from "../../assets/icons/arrow-left-with-bg.svg";
import CloseCircle from "../../assets/icons/close-circle.svg";
import Piggy from "../../assets/icons/piggy.svg";
import Download from "../../assets/icons/download.svg";
import DownloadCircle from "../../assets/icons/download-circle.svg";
import Upload from "../../assets/icons/upload.svg";
import Naira from "../../assets/icons/naira.svg";
import Forward from "../../assets/icons/forward.svg";
import BankCircle from "../../assets/icons/bank-circle.svg";
import PlusCircle from "../../assets/icons/plus-circle.svg";
import OverdraftCircle from "../../assets/icons/overdraft-circle.svg";
import BorrowFromOverdraft from "../../assets/icons/borrow-from-overdraft.svg";
import GTBank from "../../assets/icons/gt-bank.svg";
import Foreign from "../../assets/icons/foreign.svg";
import Caution from "../../assets/icons/caution.svg";

const icons = {
  card: Card,
  bankTransfer: BankTransfer,
  upDownArrow: UpDownArrow,
  walletWhiteBg: WalletWhiteBg,
  graphPopout: GraphPopout,
  search: Search,
  userMark: UserMark,
  settings: Settings,
  exit: Exit,
  copy: Copy,
  dropdown: Dropdown,
  arrowLeftWithBg: ArrowLeftWithBg,
  closeCircle: CloseCircle,
  piggy: Piggy,
  download: Download,
  downloadCircle: DownloadCircle,
  upload: Upload,
  naira: Naira,
  forward: Forward,
  bankCircle: BankCircle,
  plusCircle: PlusCircle,
  borrowFromOverdraft: BorrowFromOverdraft,
  overdraftCircle: OverdraftCircle,
  gtBank: GTBank,
  foreign: Foreign,
  caution: Caution,
};

export default icons;
