import { useEffect, useState } from "react";
import { formatNumberInThousand } from "../../../utils/functions";
import styles from "./transaction-data-summary.module.css";
import ArrowUpRed from "../../../assets/icons/dashboard/arrow-up-red.svg";
import ArrowDownGreen from "../../../assets/icons/dashboard/arrow-down-green.svg";
import { TransactionDataSummaryChart } from "./TransactionDataSummaryChart";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../../store/slices/dashboard/dashboardSlice";

export default function TransactionDataSummary({
  data,
  details,
  isTransaction,
}) {
  return (
    <div className="bgWhite cardShadow p-3 flex-xl-row flex-column h-100">
      <LeftSection
        data={data}
        merchants={details}
        isTransaction={isTransaction}
      />
      <RightSection data={details} />
    </div>
  );
}

function LeftSection({ data, merchants, isTransaction }) {
  const { transactions, total_transactions } = useSelector(dashboardSelector).dashboard;
  const [totalAmount, setTotalAmount] = useState(0);
  const [todayTransactions, setTodayTransactions] = useState([]);
  useEffect(() => {
    let tAmount = 0;
    transactions.forEach((transaction) => {
      tAmount += Number(transaction.amount);
    });
    setTotalAmount(tAmount);

    let tTransactions = transactions.filter(
      (each) =>
        new Date(each.createdAt).toISOString() === new Date().toISOString()
    );
    setTodayTransactions(tTransactions);
  }, [transactions])

  return (
    <div className="w-100 d-flex flex-column justify-content-between">
      <div>
        <div className="text-secondary fw-bold mb-2">{data.title}</div>
        <div className="h2 fw-bold textPrimary">
          {" "}
          &#8358; {formatNumberInThousand(total_transactions.total_transaction.total_transaction)}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center extraSmallText me-2">
          <img src={ArrowDownGreen} alt="deposit" />
          <div className="ms-1">
            <div className="fw-bold">
              {(merchants && !isTransaction) && merchants.filter(
                each =>
                  (new Date(each.createdAt).toDateString() === new Date().toDateString())
              ).length}
              {isTransaction && todayTransactions.length}
            </div>
            <div className={styles.successText}>
              {isTransaction ? "Transactions made today" : "Joined Today"}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center extraSmallText">
          <img src={ArrowUpRed} alt="deposit" />
          <div className="ms-1">
            <div className="fw-bold">
              &#8358; {formatNumberInThousand(total_transactions.break_down.negativeSum)}
              {" "}
            </div>
            <div className={styles.successText}>Withdrawals</div>
          </div>
        </div>
        <div className="d-flex align-items-center extraSmallText ms-3">
          <img src={ArrowDownGreen} alt="deposit" />
          <div className="ms-1">
            <div className="fw-bold">
              {" "}
              &#8358; {formatNumberInThousand(total_transactions.break_down.positiveSum)}
            </div>
            <div className={styles.successText}>Deposit</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RightSection({ data }) {
  const [selected, setSelected] = useState("day");

  function handleClick(e) {
    setSelected(e.target.value);
  }

  return (
    <div className="w-100 mt-4 mt-xl-20">
      <div
        className="btn-group w-100"
        role="group"
        aria-label="Total transaction toggle"
      >
        {/* <input
          type="radio"
          className="btn-check"
          onClick={handleClick}
          value="day"
          name="btnradio"
          id="btnradio1"
          autoComplete="off"
          checked={selected === "day"}
          readOnly
        />
        <label
          className={`btn unselectedGraphButton ${selected === "day" && "selectedGraphButton"
            }`}
          htmlFor="btnradio1"
        >
          Day
        </label> */}

        <input
          type="radio"
          className="btn-check"
          onClick={handleClick}
          value="week"
          name="btnradio"
          id="btnradio2"
          autoComplete="off"
          checked={selected === "week"}
          readOnly
        />
        <label
          className={`btn unselectedGraphButton ${selected === "week" && "selectedGraphButton"
            }`}
          htmlFor="btnradio2"
        >
          Week
        </label>

        <input
          type="radio"
          className="btn-check"
          onClick={handleClick}
          value="month"
          name="btnradio"
          id="btnradio3"
          autoComplete="off"
          checked={selected === "month"}
          readOnly
        />
        <label
          className={`btn unselectedGraphButton ${selected === "month" && "selectedGraphButton"
            }`}
          htmlFor="btnradio3"
        >
          Month
        </label>
      </div>
      <div>
        <TransactionDataSummaryChart data={data} selected={selected} />
      </div>
    </div>
  );
}
