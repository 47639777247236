import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import styles from "./hello-admin.module.css";

export default function HelloAdmin() {
  const {userData: {role, first_name}} = useSelector(loginSelector).login;
  return (
    <div
      className={["bgWhite cardShadow p-3 h-100", styles.helloAdmin].join(" ")}
    >
      <div className="w-75">
        <h3 className="fw-bold text-capitalize">Hello {first_name}</h3>
        <p className="h4 text-secondary my-5">
          Welcome to Gate Administrator Dashboard
        </p>
        {role === "super-admin" &&
          <Link to="/teams/add" className="btn customPrimaryButton">
            Add new member to team
          </Link>
        }
      </div>
    </div>
  );
}
