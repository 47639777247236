import axios from "axios";
import {useEffect, useMemo, useState} from "react";
import {Toaster} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import LayoutProtected from "../layouts/LayoutProtected";
import {loginSelector} from "../store/slices/auth/loginSlice";
import {initState} from "../store/slices/dashboard/dashboardSlice";
import verify, {interceptRequest, interceptResponse} from "../utils/verify";
import FetchError from "../widgets/others/FetchError";
import Loader from "../widgets/others/Loader";

const ProtectedRoute = ({component: Component}) => {
  const dispatch = useDispatch();
  const {userData} = useSelector(loginSelector).login;

  useEffect(() => {
    // to set all loading state from redux:persist to false
    dispatch(initState());
  }, [dispatch]);

  //for loading state
  const loader = <Loader height={"100vh"} withLogo />;

  // to navigate to signin when userData?.user_token is invalid
  const navigation = useMemo(() => <Navigate to="/auth" replace />, []);

  // for other error
  const networkError = useMemo(
    () => (
      <FetchError
        withLogo
        minHeight={"100vh"}
        message={"An error occured, please check your internet connection."}
        onClick={() => window.location.reload()}
        btnText={"REFRESH"}
      />
    ),
    []
  );

  // loading state is the default active component
  const [activeComponent, setActiveComponent] = useState(loader);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Add a request interceptor to always attach userData?.user_token to header
    interceptRequest(axios, {
      token: userData?.user_token,
      setActiveComponent,
      navigation,
    });

    // Add a request response to always check if user is still authorized
    interceptResponse(axios, {
      setActiveComponent,
      navigation,
    });

    // to verify if the userData?.user_token is valid before access to protected routes
    verify(axios, {
      token: userData?.user_token,
      dispatch,
      setActiveComponent,
      navigation,
      setSuccess,
      networkError,
      user: {id: userData?.id, role: userData?.role},
    });
    //eslint-disable-next-line
  }, [navigation, networkError, userData?.user_token, dispatch]);

  return success ? (
    <LayoutProtected>
      <Component />
      <Toaster position="bottom-left" />
    </LayoutProtected>
  ) : (
    activeComponent
  );
};

export default ProtectedRoute;
