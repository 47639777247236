import React, {useEffect, useState} from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import TransactionsTable from "../../components/transactions/TransactionsTable";
import {useDispatch, useSelector} from "react-redux";
import {
  dashboardSelector,
  getTransactions,
} from "../../store/slices/dashboard/dashboardSlice";
import Loader from "../../widgets/others/Loader";
import FetchError from "../../widgets/others/FetchError";

export default function Transactions() {
  const {transactions, transactionsIsLoading, transactionsError} =
    useSelector(dashboardSelector).dashboard;
  const dispatch = useDispatch();
  const [filteredTransactions, setFilteredTransactions] =
    useState(transactions);
  const [searchedTransactions, setSearchedTransactions] =
    useState(filteredTransactions);
  const [searchValue, setsearchValue] = useState("");
  const [filterValues, setFilterValues] = useState({entries: 0});

  useEffect(() => {
    // set argument true for background fetch not affecting the ui
    dispatch(getTransactions(true));
  }, [dispatch]);

  useEffect(() => {
    setFilteredTransactions(transactions);
    setFilter(filterValues);
    //eslint-disable-next-line
  }, [transactions]);

  const setFilter = (values) => {
    setFilterValues(values);
    setFilteredTransactions(
      transactions.filter(
        (each, i) =>
          Number(values.entries) === 0 || i + 1 <= Number(values.entries)
        // (!values.date || Date(each.createdAt).getMilliseconds() === Date(values.date).getMilliseconds())
      )
    );
  };

  useEffect(() => {
    setSearchedTransactions(filteredTransactions);
    searchTransactions(searchValue);
    //eslint-disable-next-line
  }, [filteredTransactions]);

  const searchTransactions = (searchText) => {
    setsearchValue(searchText);
    setSearchedTransactions(
      filteredTransactions.filter(
        (each, i) =>
          each?.sender?.first_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          each?.sender?.last_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          each.description.toLowerCase().includes(searchText.toLowerCase()) ||
          each.createdAt.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };
  return (
    <>
      <PageHeader
        title="Transactions"
        withSearch={true}
        withFilter={true}
        setFilter={setFilter}
        search={searchTransactions}
      />

      {transactionsIsLoading && <Loader height={"40vh"} />}

      {transactionsError && !transactionsIsLoading && (
        <FetchError
          message={"An error occured, please check your connection"}
          minHeight={"40vh"}
          btnText={"Try Again"}
          onClick={() => dispatch(getTransactions())}
        />
      )}

      {!transactions[0] && !transactionsIsLoading && !transactionsError && (
        <FetchError
          message={"No Transactions found"}
          minHeight={"40vh"}
          btnText={"Refresh"}
          onClick={() => dispatch(getTransactions())}
        />
      )}

      {transactions[0] && !transactionsIsLoading && !transactionsError && (
        <div className="container-fluid pt-4">
          <section className="row">
            <div className="col-sm-12">
              <TransactionsTable
                transactions={[...searchedTransactions].reverse()}
              />
            </div>
          </section>
        </div>
      )}
    </>
  );
}
