import {useState} from "react";
import styles from "./filter.module.css";

const entries = [5, 10, 20, 30, 40, 50, 100];

export default function Filter({setFilter}) {
    const [values, setValues] = useState({
        date: "",
        // 0 means all
        entries: 0
    })

    function handleChange(e) {
        setValues({...values, [e.target.name]: e.target.value});
        setFilter({...values, [e.target.name]: e.target.value})
    }

    function handleSubmit() {
        // console.log(values);
    }

    return (
        <div className={["container-fluid", styles.filter].join(' ')}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    {/* <div className="col-sm-12 mb-2">Filter/Sort By:</div> */}
                    {/* <div className="col-sm-6 col-md-2 col-6 mb-2 mb-md-0">
                        <select className="form-select customFormControl" name="bankName" onChange={handleChange} value={values.bankName} aria-label="BankName" aria-describedby="bankName">
                            <option defaultValue>Bank</option>
                            {nigerianBanksList.map((item, index) => <option key={index} value={item.id}>{item.name}</option> ) }
                        </select>
                    </div> */}
                    {/* <div className="col-sm-6 col-md-2 col-6 mb-2 mb-md-0">
                        <select className="form-select customFormControl" name="type" onChange={handleChange} value={values.type} aria-label="Type" aria-describedby="type">
                            <option defaultValue>Types</option>
                            {types.map((item, index) => <option key={index} value={item}>{item}</option> ) }
                        </select>
                    </div> */}
                    {/* <div className="col-sm-6 col-md-6 col-12 mb-2 mb-md-0">
                        <input type="date" name="startDate" className="form-control" value={values.startDate} onChange={handleChange} placeholder="Start Date" />
                    </div> */}
                    {/* <div className="col-sm-6 col-md-2 col-6 mb-2">
                        <input type="date" name="endDate" className="form-control" value={values.endDate} onChange={handleChange} placeholder="End Date" />
                    </div> */}
                    <div className="col-sm-6 col-md-6 col-12 mb-2 mb-md-0">
                        <div className="d-flex align-items-center">
                            <div>Show</div>
                            <select className="form-select bgWhite customFormControl mx-2" name="entries" onChange={handleChange} value={values.type} aria-label="Type" aria-describedby="type">
                                <option value={0}>All</option>
                                {entries.map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                            <div>entries</div>
                        </div>
                    </div>
                    {/* <div className="col-sm-6 col-md-2 col-6 mb-2 mb-md-0">
                        <button className="btn customPrimaryButton">Filter</button>
                    </div> */}
                    {/* <div className="col-sm-6 col-md-2 col-6 mb-2 mb-md-0">
                        <button onClick={() => setFilter({date: "", entries: 0})} type="button" className="btn btn-light">Clear</button>
                    </div> */}
                </div>
            </form>
        </div>
    )
}